import {
    useNavigate,
    Link 
} from 'react-router-dom';
import PendingList from 'components/SongLlist/PendingList';
import SongListItem from 'components/SongListItem';

import { ScrollPosition } from 'react-lazy-load-image-component';
import {
    ERROR,
    VIEW_TOGGLE,
} from 'constants/index';
import {
    CREATE,
    STEP_PREFIX
} from 'constants/routes';

import './SongList.scss';   

import { 
    IPlaylistData,
    IPendingTrack, 
    IPlanObject,
    ISlideDialog,
    ITrack
} from 'interfaces/index';


interface IPlaylistProps {
    currentMusicId: string;
    currentPlaylistId: string;
    playlistData?: IPlaylistData;
    createMusicQueue?: IPendingTrack[];
    // eslint-disable-next-line no-unused-vars
    updateLike: (arg: any) => any;
    title?: string;
    disabled?: boolean;
    scrollPosition?: ScrollPosition;
    displayMode?: string;
    isAuthenticated: boolean;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg: ISlideDialog) => any;
    [key: string]: any
}

function SongList(props: IPlaylistProps) { 
    const {
        currentMusicId = '',
        currentPlaylistId = '',
        playlistData = {} as IPlaylistData,
        createMusicQueue = [],
        updateLike = () => {},
        disabled = false,
        scrollPosition,
        displayMode = '',
        setSlideDialogOpen = ()=>{},
    } = props;

    const {
        id = '',
        title = '',
        musics,
        status = ''
    } = playlistData;

    const navigate = useNavigate();

    // loading state - it is very quick because all playlists are already loaded
    if (status !== ERROR && !musics) {
        return null;
    }

    if (!Array.isArray(musics) || status === ERROR) {
        return (
            <div className="song-list">
                <h3>
                    {title}
                </h3>
                <p>
                    Not available at the moment.
                </p>
            </div>
        );
    }

    // eslint-disable-next-line no-unused-vars
    const handleChangeMusic = (e: any, data: IPlanObject) => {
        e.preventDefault();
    
        if (disabled) {
            return;
        }
    
        const {
            playlistId,
            musicId,
        } = data;
    
        if (playlistId && musicId) {
            navigate(`/${playlistId}/${musicId}`, { state: { playMusic: true, scrollTop: false } });
        }
    };

    const playlistId = id;
    const isEmptyUserPlaylist = !playlistData.musics?.length;
    const isListMode = displayMode === VIEW_TOGGLE.LIST;

    if (isEmptyUserPlaylist) {
        return (
            <div className="song-list">
                <p>You haven&apos;t create any song yet.</p>
                <p className='mb-4'>
                    <Link to={`${CREATE}/${STEP_PREFIX}1`}>Create</Link> your own songs now
                </p>
            </div>
        );
    }
    
    const gridCss = !isListMode 
        ? 'song-list__list--grid'
        : '';

    return (
        <div className="song-list">
            <PendingList createMusicQueue={createMusicQueue} />
            <ul className={`song-list__list ${gridCss}`}>
                {
                    playlistData.musics?.map((track: ITrack, index: number) => {
                        const isSelectedSong = (track.musicId === currentMusicId && playlistId === currentPlaylistId);

                        return (
                            <li 
                                key={track.musicId}
                                className={`${isSelectedSong ? 'song-list__list--active' : ''}`.trim()}
                            >
                                <SongListItem 
                                    idx={index}
                                    playlistId={playlistId}
                                    {...track}
                                    isSelected={!disabled && isSelectedSong}
                                    updateLike={updateLike}
                                    onClick={handleChangeMusic}
                                    disabled={disabled}
                                    scrollPosition={scrollPosition}
                                    asList={isListMode}
                                    onMoreClick={setSlideDialogOpen}
                                />
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
}

export default SongList;
