import React, { 
    useEffect,
    useState,
    Suspense,
} from 'react';
import { 
    useParams,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { Container } from '@mui/material';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import MiniPlayer from 'containers/MiniPlayer';
import SongList from 'containers/SongList';
import CreateButton from 'components/CreateButton';
import ShareButton from 'containers/ShareButton';
import {
    deviceInfo,
    updateMetaTag 
} from 'utils/index';
import TopSongs from 'containers/TopSongs';
import PlaylistsScroller from 'containers/PlaylistsScroller';
import PlaylistToggle from 'containers/PlaylistToggle';
import SlideDialog from 'components/Modal/SlideDialog';
import AddSong from 'containers/AddSong';
import { 
    VIEW_TOGGLE,
    REFRESH_PLAYLIST_TIME,
    SUCCESS,
} from 'constants/index';
import { 
    DEFAULT_PLAYLIST_ID,
    TOP_SONGS_PLAYLIST_ID,
} from 'constants/api';

import './Home.scss';

import {
    IPendingTrack, 
    IPlaylists,
    IChangeMusic,
    ITrack,
    ICurrentMusic,
    IMusicState,
    IAccountPlaylistsState,
    IPlanObject,
    ISlideDialog,
} from 'interfaces';

// @ts-ignore
const SongListTrackedScroll = trackWindowScroll(SongList);

interface IHomeProps {
    music: IMusicState;
    // eslint-disable-next-line no-unused-vars
    getPlaylist: (arg: any) => any;
    // eslint-disable-next-line no-unused-vars
    changeMusic: (arg: IChangeMusic) => any;
    // eslint-disable-next-line no-unused-vars
    setPlayerOpen: (arg: boolean) => any;
    // eslint-disable-next-line no-unused-vars
    getPlaylistsMusics: (arg: IPlanObject) => any;
    createMusicQueue?: IPendingTrack[];
    isAuthenticated?: boolean;
    userPlaylists?: IAccountPlaylistsState;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg: ISlideDialog) => any;
    [key: string]: any;
}

const ShareBar = deviceInfo.isDesktop ? React.lazy(() => import('containers/ShareBar')) : React.Fragment;

// The player will play the first music from this playlist.
// set 'Top 10' or 'Latest' playlists id
const defaultPlaylistId = DEFAULT_PLAYLIST_ID; // TOP_SONGS_PLAYLIST_ID; 

function Home(props: IHomeProps) {
    const {
        music = {} as IMusicState,
        getPlaylist = () => {},
        changeMusic = () => {},
        setPlayerOpen = () => {},
        getPlaylistsMusics =() => {},
        isAuthenticated = false,
        userPlaylists = {} as IAccountPlaylistsState,
        createMusicQueue,
        setSlideDialogOpen= ()=>{},
    } = props;

    const {
        currentMusic = {} as ICurrentMusic,
        playlists = {} as IPlaylists,
        loadPlaylistsStatus = '',
        slideDialog = {},
    } = music;

    const location = useLocation();
    const navigate = useNavigate();

    // get url params
    const { 
        playlistId = '', 
        musicId 
    } = useParams();

    const [isDefaultPlaylistsLoaded, setIsDefaultPlaylistsLoaded] = useState(false);
    const [isDefaultMusicSet, setDefaultMusicSet] = useState(false);
    // const [isUsersPlaylistLoaded, setIsUsersPlaylistLoaded] = useState(false);
    const [playlistMode, setPlaylistMode] = useState(VIEW_TOGGLE.GALLERY);

    // onMount - get 'top 10' and 'default' playlists data
    useEffect(() => {
        const asyncFn = async () => {
            // playslists IDs for default view.
            const allPlaylists = [
                TOP_SONGS_PLAYLIST_ID,
                DEFAULT_PLAYLIST_ID,
            ];
            // 1. loop allPlaylists to create array of promises
            // 2. parallel call using Promise.allSettled
            const playlistPromises = allPlaylists.map((id = '') => getPlaylist({ playlistId: id }));
            // eslint-disable-next-line no-unused-vars
            const playlistResults = await Promise.allSettled(playlistPromises);

            setIsDefaultPlaylistsLoaded(true);
        };

        asyncFn();
    }, [setIsDefaultPlaylistsLoaded]);

    // get playlist and song when playlistId or musicId present in url
    useEffect(() => {
        const changeDefaultMusic = () => {
            // 3. if there is 'musicId' from URL ('/playlistId/musicId')
            // change music by finding match musicId in loaded playlists
            const currentPlaylistId = playlists[playlistId] 
                ?  playlists[playlistId].id 
                : defaultPlaylistId;

            const targetPlaylist = playlists[currentPlaylistId] && playlists[currentPlaylistId].musics[0]
                ? playlists[currentPlaylistId]
                : playlists[defaultPlaylistId || ''];
            
            let currentMusicId = musicId;
            let currentMusicIndex = 0;

            // find currentMusicIndex
            if (currentMusicId && targetPlaylist) {
                if (Array.isArray(targetPlaylist.musics)) {
                    const musicIndex = targetPlaylist.musics.findIndex((m: ITrack) => m.musicId === currentMusicId);
                    currentMusicIndex = musicIndex >= 0
                        ? musicIndex
                        : 0;
                }
            } else {
                // find currentMusicId - default to defaultPlaylist 1st music
                // set currentMusicId as first musicId from  matched playlist
                currentMusicId = targetPlaylist.musics[0]
                    ? targetPlaylist.musics[0].musicId
                    : '';
            }

            if (targetPlaylist && targetPlaylist.musics?.length) {
                const {
                    musicName = '',
                    cover = '',
                } = targetPlaylist.musics[currentMusicIndex];

                updateMetaTag({
                    tagName: 'og:title',
                    content: musicName,
                });

                updateMetaTag({
                    tagName: 'og:image',
                    content: cover,
                });

                changeMusic({
                    playlistId: currentPlaylistId,
                    musicId: currentMusicId,
                    index: currentMusicIndex
                });

                setDefaultMusicSet(true);
            }
        };

        if (isDefaultPlaylistsLoaded) {
            // redirect to home, if playlistId doesn't match
            // @ts-ignore
            if (playlistId && !playlists[playlistId]) {
                navigate('/', { replace: true });
                return;
            }

            // set default music or land from shared url. Only run once
            // on music route change
            if (playlistId && !musicId) {
                changeDefaultMusic();
                return;
            }

            if (currentMusic.musicId) {
                if (playlistId && currentMusic.musicId !== musicId) {
                    changeMusic({
                        playlistId,
                        musicId,
                    });
                }
            } else if (!isDefaultMusicSet) {
                // on mount 
                changeDefaultMusic();
                // open music detail
                if (playlistId && musicId && !currentMusic.isPlayerOpen) {
                    setPlayerOpen(true);
                }
            }
        }
        
    }, [playlists, playlistId, musicId, isDefaultPlaylistsLoaded, isDefaultMusicSet, setDefaultMusicSet]);

    // fetch user's playlists
    useEffect(()=> {
        if (Object.keys(userPlaylists).length) {
            getPlaylistsMusics(userPlaylists);
        }
    }, [userPlaylists]);


    useEffect(() => {
        // close modal on home url
        if ((location.pathname === '/') && currentMusic.isPlayerOpen) {
            setPlayerOpen(false);
        }
    },[location]);

    // Refresh default playlist when there is item in createMusicQueue
    // the reducer will remove the queue item if new song added into the playlist
    // NOTE: the Router will unmount the component between routes
    useEffect(()=> {
        let refreshTimer: any;

        if (Array.isArray(createMusicQueue)) {
            if (createMusicQueue.length) {
                clearTimeout(refreshTimer);
                refreshTimer = setTimeout(()=> {
                    getPlaylist({ playlistId: DEFAULT_PLAYLIST_ID });
                }, REFRESH_PLAYLIST_TIME);
            } else {
                clearTimeout(refreshTimer);
            }
        }

        return  function cleanUp() {
            if (Array.isArray(createMusicQueue) && !createMusicQueue.length) {
                clearTimeout(refreshTimer);
            }
        };
    }, [createMusicQueue]);

    const isUsersPlaylistLoaded = loadPlaylistsStatus === SUCCESS;

    let currentPlaylistData = isDefaultPlaylistsLoaded 
        ? playlists[currentMusic.playlistId]
        : {};

    // always use default playlist before login
    if (!isAuthenticated) {
        currentPlaylistData = playlists[DEFAULT_PLAYLIST_ID || ''];
    }

    // user's playlist
    if (isAuthenticated && playlistId && !musicId && isUsersPlaylistLoaded) {
        currentPlaylistData = playlists[playlistId];
    }

    const currentPlaylistTitle = currentPlaylistData?.title || '';

    return (
        <Container 
            className="home"
            maxWidth="lg"
        >
            <div className="home__intro">
                <h2>Make Your Own Songx</h2>
                <div className="home__intro-butons-section">
                    <CreateButton />
                    {!deviceInfo.isDesktop && <ShareButton asTextButton />}
                </div>
                <Suspense>
                    <ShareBar />
                </Suspense>
            </div>
            <MiniPlayer 
                // @ts-ignore
                isModalOpen={currentMusic.isPlayerOpen}
                setIsModalOpen={setPlayerOpen}
            />
            { isAuthenticated && <PlaylistsScroller currentPlaylistData={currentPlaylistData} />}
            { !isAuthenticated && <TopSongs />}
            {isDefaultPlaylistsLoaded &&  (
                <>
                    <PlaylistToggle 
                        className="mt-2 mb-1"
                        playlistTitle={currentPlaylistTitle}
                        onViewChange={setPlaylistMode}
                    />
                    <SongListTrackedScroll 
                        // @ts-ignore
                        playlistData={currentPlaylistData}
                        displayMode={playlistMode}
                    />
                    <SlideDialog 
                        open={!!slideDialog.isOpen}
                        onClose={setSlideDialogOpen}
                    >
                        <AddSong />
                    </SlideDialog>
                </>
            )}
        </Container>
    );
}

export default Home;
