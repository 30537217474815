export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
// Firebase GS storage reference
export const GS_STORAGE_DOMAIN = 'gs://moonlit-watch-404012.appspot.com';
export const MIX_MUSIC_STORAGE_PATH = `${GS_STORAGE_DOMAIN}/audio/musics/`;

// Playlists
export const CREATE_PLAYLIST_API = `${API_DOMAIN}/music/playlist/v2`;
export const GET_PLAYLISTS_API = `${API_DOMAIN}/music/playlists`;

// Playlist {playlistId}
export const PLAYLIST_API = `${API_DOMAIN}/music/playlist`;

// music
export const MUSIC_API = `${API_DOMAIN}/music/getMusic`;
export const ARTISTS_API = `${API_DOMAIN}/music/rapArtists/v3`;
export const MUSIC_SELECTION_API = `${API_DOMAIN}/music/mixMusicSelection_v2`;

// create music
export const MUSIC_CREATE_API = `${API_DOMAIN}/music/createMusic/v2`;
export const MUSIC_CREATE_PLAYLIST = `${API_DOMAIN}/music/playlist/v2`;

// social update music
export const MUSIC_UPDATE_LIKE_API = `${API_DOMAIN}/music/social/like`;
export const MUSIC_UPDATE_VIEW_API = `${API_DOMAIN}/music/social/view`;
export const TOP_LIKES_API =`${API_DOMAIN}/music/topLikes`;

// user
export const USER_PROFILE_API = `${API_DOMAIN}/user/songx_profile`;
export const USER_PROFILE_UPDATE_API = `${API_DOMAIN}/user/songx_profile/update`;
export const VERIFY_TOKEN_API = `${API_DOMAIN}/user/verify_token`;

// constants
export const DEFAULT_PLAYLIST_ID = process.env.REACT_APP_DEFAULT_PLAYLIST_ID;
export const TOP_SONGS_PLAYLIST_ID = process.env.REACT_APP_TOP_SONGS_PLAYLIST_ID;

export const AXIOS_NO_CACHE_HEADERS = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};
