import { 
    useState, 
    useMemo 
} from 'react';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ShareIcon from '@mui/icons-material/Share';
import * as tracker from 'utils/tracker';
import { MOBILE_SHARE } from 'constants/trackingEvents';
import { 
    shareMusic, 
    findMusicDetails
} from 'utils';
import {
    ICurrentMusic,
    IPlanObject, 
    ISlideDialog, 
} from 'interfaces';
import {
    ERROR,
    SUBMITTING,
    OPERATIONS,
} from 'constants/index';
import { 
    DEFAULT_PLAYLIST_ID,
    TOP_SONGS_PLAYLIST_ID,
} from 'constants/api';
import { SIGN_IN } from 'constants/routes';
import UpdatePlaylistForm from './UpdatePlaylistForm';

import './AddSong.scss';

interface IAddSongProps {
    className?: string;
    slideDialog?: ISlideDialog;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg:ISlideDialog) => any;
    isAuthenticated: boolean;
    userPlaylists: IPlanObject;
    // eslint-disable-next-line no-unused-vars
    updatedMusicInPlaylist: (arg: IPlanObject) => any;
}

function AddSong(props: IAddSongProps) {
    const {
        className = '',
        slideDialog = {} as ISlideDialog,
        setSlideDialogOpen = ()=>{},
        isAuthenticated = false,
        userPlaylists = {},
        updatedMusicInPlaylist = ()=>{},
    } = props;

    const [status, setStatus] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [operation, setOperation] = useState(OPERATIONS.ADD);

    const navigate = useNavigate();

    const {
        musicId ='',
        playlistId = '',
        index = 0,
    } = slideDialog;

    const musicDeatils: any = useMemo(
        () => findMusicDetails({
            musicId,
            playlistId,
            index
        } as ICurrentMusic),
        [musicId, playlistId, index]
    );

    const isSubmitting = status === SUBMITTING;

    const userPlaylistsArray = Object.values(userPlaylists);

    const handleAddToPlaylist = () => {
        if (!isAuthenticated) {
            navigate(SIGN_IN);
            return;
        }
        setOperation(OPERATIONS.ADD);
        setShowForm(true);
    };

    const handleRemoveFromPlaylist = ()=>{
        if (!isAuthenticated) {
            navigate(SIGN_IN);
            return;
        }
        setOperation(OPERATIONS.REMOVE);
        setShowForm(true);
    };

    const handleShare = async () => {
        try {
            const opt = {
                musicId,
                playlistId
            };
            if (!musicId || !playlistId) {
                return;
            }
            
            const sharedOptions = await shareMusic(opt);

            // close this dialog
            setSlideDialogOpen({ isOpen: false });

            if (sharedOptions) {
                tracker.event(MOBILE_SHARE, { shareUrl: sharedOptions.url || '' });
            }
            
        } catch(err) {
            // console.error(err);
        }
    };

    const handleFormSubmit = async (values: any) => {
        try {
            if (isSubmitting) {
                return;
            }

            setStatus(SUBMITTING);

            // action
            const result = await updatedMusicInPlaylist({
                musicId,
                currentPlaylistId: playlistId,
                targetPlaylistId: values.playlistId,
                operation,
            });

            if (result) {
                // close this dialog
                setSlideDialogOpen({ isOpen: false });
            }
        } catch (err) {
            setStatus(ERROR);
        }
    };

    const handleCancel = ()=>{
        // close this dialog
        setSlideDialogOpen({ isOpen: false });
    };

    // don't show remove from playlist if current music is in default playlist or top song playlist
    const showRemoveFromPlaylist = !(playlistId === DEFAULT_PLAYLIST_ID || playlistId === TOP_SONGS_PLAYLIST_ID);

    const classNames = `add-song ${className}`.trim();

    return (
        <div className={classNames}>
            {!showForm && (
                <List 
                    className='add-song__nav'
                    component="nav"
                    disablePadding
                >
                    <ListItemButton
                        onClick={handleAddToPlaylist}
                    >
                        <ListItemIcon>
                            <AddCircleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Add to playlist" />
                    </ListItemButton>
                    {showRemoveFromPlaylist && (
                        <ListItemButton
                            onClick={handleRemoveFromPlaylist}
                        >
                            <ListItemIcon>
                                <HighlightOffIcon />
                            </ListItemIcon>
                            <ListItemText primary="Remove from playlist" />
                        </ListItemButton>
                    )}
                    <ListItemButton
                        onClick={handleShare}
                    >
                        <ListItemIcon>
                            <ShareIcon />
                        </ListItemIcon>
                        <ListItemText primary="Share" />
                    </ListItemButton>
                </List>
            )}

            {showForm && (
                <UpdatePlaylistForm
                    status={status}
                    operation={operation}
                    musicDeatils={musicDeatils}
                    playlists={userPlaylistsArray}
                    playlistId={playlistId}
                    handleFormSubmit={handleFormSubmit}
                    handleCancel={handleCancel}
                />
            )}
        </div>
    );
}

export default AddSong;
