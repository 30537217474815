import { 
    LazyLoadImage,
    ScrollPosition
} from 'react-lazy-load-image-component';
import MoreButton from 'components/MoreButton';

import { 
    Grid, 
    IconButton,
} from '@mui/material';
import React from 'react';

interface IListViewProps {
    musicName?: string;
    author?: string;
    duration?: string;
    imageSrc?: string;
    disabled?: boolean;
    showLikeIcon?: boolean;
    likeIcon?: React.ReactNode;
    liked?: boolean;
    // eslint-disable-next-line no-unused-vars
    handleLikeClick?: (arg: any) => any;
    // eslint-disable-next-line no-unused-vars
    handleMore?: (arg: any)=> any;
    scrollPosition?: ScrollPosition; 
}

function ListView(props: IListViewProps) {
    const {
        musicName = '',
        author = '',
        duration = '',
        imageSrc,
        disabled = false,
        showLikeIcon = true,
        likeIcon = null,
        liked = false,
        handleLikeClick = ()=>{},
        handleMore = ()=>{},
        scrollPosition = {} as ScrollPosition,
    } = props;

    const isShowLikeIcon = showLikeIcon && !disabled;

    return (
        <Grid 
            container
            spacing={0}
            className="song-list-item__list-view"
        >
            <Grid 
                item 
                xs={10} 
                sm={10}
                className="pl-0"
            >
                <div className='song-list-item__song-detail'>
                    <LazyLoadImage
                        className="song-list-item__song-img" 
                        src={imageSrc}
                        alt={`cover art ${musicName}`}
                        scrollPosition={scrollPosition}
                    />
                    <div>
                        <h5 className="text-truncated">
                            {musicName}
                        </h5>
                        <small className="text-truncated">
                            {author}
                        </small>
                        {duration && (<small>{duration}</small>)}
                    </div>
                </div>
            </Grid>
            <Grid 
                item 
                xs={2}
                sm={2} 
                className='song-list-item__col-right'
            >
                {isShowLikeIcon && likeIcon && (
                    <IconButton
                        href="#"
                        className={`song-list-item__btn-like ${liked ? 'liked' : ''}`} 
                        color="inherit"
                        onClick={handleLikeClick}
                    >
                        {likeIcon}
                    </IconButton>
                )}
                <MoreButton onClick={handleMore} />
            </Grid>
        </Grid>
    );
}

export default ListView;
