import axios from 'axios';
import {
    LOADING_MUSIC,
    LOAD_MUSIC_SUCCESS,
    LOAD_MUSIC_ERROR,
    LOADING_PLAYLIST,
    LOAD_PLAYLIST_SUCCESS,
    LOAD_PLAYLIST_ERROR,
    UPDATE_CURRENT_MUSIC_INDEX,
    UPDATE_LIKE,
    UPDATE_VIEW,
    UPDATE_PLAYER_STATUS,
    UPDATE_PLAYER_OPEN_STATE,
    UPDATE_SLIDE_DIALOG_OPEN_STATE,
    UPDATE_REPEAT_PLAYLIST,
    UPDATE_REPEAT_SINGLE,
    UPDATE_SHUFFLE_PLAYLIST,
    LOADING_PLAYLISTS,
    LOAD_PLAYLISTS_SUCCESS,
    LOAD_PLAYLISTS_ERROR
} from 'constants/actionTypes';
import { getPlaylistUpdateDto } from 'utils/index';
import { 
    convertPlaylistData,
    convertMusicData,
} from 'dataConverter';
import { 
    PLAYLIST_API,
    MUSIC_API,
    MUSIC_UPDATE_VIEW_API,
    MUSIC_UPDATE_LIKE_API,
    DEFAULT_PLAYLIST_ID,
    AXIOS_NO_CACHE_HEADERS,
} from 'constants/api';
import { OPERATIONS } from 'constants/index';
import {
    IPlanObject, 
    IChangeMusic,
    ISlideDialog,
} from 'interfaces';

const getPlaylistData = (playlistId: string, noCache?: boolean) => {
    const headers = noCache 
        ? AXIOS_NO_CACHE_HEADERS
        : {};

    return axios({
        method: 'get',
        url: `${PLAYLIST_API}/${playlistId}`,
        headers,
    }).then((response) => {
        const {
            data = {},
        } = response;
    
        const playlistData = convertPlaylistData(data);
    
        if (playlistData.id === DEFAULT_PLAYLIST_ID) {
            playlistData.title = 'Latest';
        }
    
        return playlistData;
    });
};

export const getPlaylist: any = (opt = {} as IPlanObject) => async (dispatch: any) => {
    const {
        playlistId = '',
        noCache,
    } = opt;

    try {
        if (!playlistId) {
            return Promise.reject(new Error('missing playlistId'));
        }

        dispatch({ 
            type: LOADING_PLAYLIST, 
            payload: { playlistId } 
        });

        const playlistData = await getPlaylistData(playlistId, noCache);

        dispatch({
            type: LOAD_PLAYLIST_SUCCESS,
            payload: {
                playlistId,
                playlistData,
            }
        });

        return playlistData;
    } catch(error) {
        dispatch({ 
            type: LOAD_PLAYLIST_ERROR,
            payload: { playlistId }
        });
        return error;
    }
};

export const getMusic = (opt = {} as IPlanObject) => (dispatch: any) => {
    dispatch({ type: LOADING_MUSIC });

    const {
        musicId = '',
    } = opt;

    if (!musicId) {
        dispatch({
            type: LOAD_MUSIC_SUCCESS,
            payload: {},
        });

        return Promise.resolve({});
    }

    return axios({
        method: 'get',
        url: `${MUSIC_API}/${musicId}`,
    }).then((response) => {
        const {
            data,
        } = response;

        const musicData = convertMusicData(data);

        dispatch({
            type: LOAD_MUSIC_SUCCESS,
            payload: musicData,
        });

        return musicData;
    }).catch((error: any) => {
        dispatch({ type: LOAD_MUSIC_ERROR });

        return error;
    });
};

export const changeMusic = (data = {} as IChangeMusic) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_CURRENT_MUSIC_INDEX,
        payload: data
    });
};

export const updatePlayerStatus = (isPlaying = false) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_PLAYER_STATUS,
        payload: isPlaying
    });
};

export const updateLike = (data = {} as IPlanObject) => (dispatch: any) => {
    const {
        musicId = '',
    } = data;

    // TODO - debounce this call as user allow to keep pressing like button
    // make 1 call with accumulated likes number
    dispatch({ 
        type: UPDATE_LIKE,
        payload: data
    });

    // API to patch like only
    return axios({
        method: 'post',
        url: `${MUSIC_UPDATE_LIKE_API}/${musicId}`,
    }).then((response)=> {
        const {
            // eslint-disable-next-line no-shadow, no-unused-vars
            data,
        } = response;
        // console.log('updateLike res: ', data);
        // eslint-disable-next-line no-unused-vars
    }).catch((error: any)=>{
        // console.log('updateLike error: ', error);
    });
};

// updateView means user listen to the music on the player, everytime.
export const updateView = (data = {} as IPlanObject) => (dispatch: any) => {
    const {
        musicId = '',
    } = data;

    dispatch({ 
        type: UPDATE_VIEW,
        payload: data
    });

    // API to patch like only
    return axios({
        method: 'post',
        url: `${MUSIC_UPDATE_VIEW_API}/${musicId}`,
    }).then((response)=> {
        const {
            // eslint-disable-next-line no-shadow, no-unused-vars
            data,
        } = response;
        // console.log('updateLike res: ', data);
        // eslint-disable-next-line no-unused-vars
    }).catch((error: any)=>{
        // console.log('updateLike error: ', error);
    });
};

export const setPlayerOpen = (isOpen = false) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_PLAYER_OPEN_STATE,
        payload: isOpen
    });
};

export const setSlideDialogOpen = (data = {} as ISlideDialog) => (dispatch: any) => {
    dispatch({ 
        type: UPDATE_SLIDE_DIALOG_OPEN_STATE,
        payload: data
    });
};

export const setRepeatPlaylist = (isRepeat: boolean) => (dispatch: any) => {
    dispatch({
        type: UPDATE_REPEAT_PLAYLIST,
        payload: isRepeat
    });
};

export const setRepeatSingle = (isRepeat: boolean) => (dispatch: any) => {
    dispatch({
        type: UPDATE_REPEAT_SINGLE,
        payload: isRepeat
    });
};

export const setShufflePlaylist = (isShuffle: boolean) => (dispatch: any) => {
    dispatch({
        type: UPDATE_SHUFFLE_PLAYLIST,
        payload: isShuffle
    });
};

export const getPlaylistsMusics = (playlists: IPlanObject) => async (dispatch: any) => {
    try {
        const playlistPromises = [];

        dispatch({ type: LOADING_PLAYLISTS });

        // eslint-disable-next-line no-restricted-syntax, no-unused-vars
        for (const [key, value] of Object.entries(playlists)) {
            const {
                id = '' 
            } = value;
            playlistPromises.push(getPlaylistData(id));
        }

        const result = await Promise.all(playlistPromises);

        const playlistsObject = {} as IPlanObject;

        result.forEach((playlist) => {
            if (playlist.id) {
                playlistsObject[playlist.id] = playlist;
            }
        });

        dispatch({ 
            type: LOAD_PLAYLISTS_SUCCESS,
            payload: playlistsObject
        });
    } catch(err) {
        dispatch({ 
            type: LOAD_PLAYLISTS_ERROR,
            payload: err
        });

        throw err;
    }
};

export const updatedMusicInPlaylist = (data = {} as IPlanObject) => async (dispatch: any) => {
    const {
        musicId = '',
        currentPlaylistId = '',
        targetPlaylistId = '',
        operation = '',
    } = data;

    const dto = getPlaylistUpdateDto(targetPlaylistId);

    if (!musicId || !dto) {
        return undefined;
    }

    if (operation === OPERATIONS.ADD) {
        if (dto.musicIds.indexOf(musicId) < 0) {
            dto.musicIds.push(musicId);
        }
    }

    if (operation === OPERATIONS.REMOVE) {
        dto.musicIds = dto.musicIds.filter((id: string) => id !== musicId);
    }

    return axios({
        method: 'post',
        url: `${PLAYLIST_API}/${targetPlaylistId}`,
        data: dto
    }).then((response)=> {
        // sync updated playist
        dispatch(getPlaylist({ playlistId: targetPlaylistId, noCache: true }));
        dispatch(getPlaylist({ playlistId: currentPlaylistId, noCache: true }));
        return response.data;
    }).catch((error: any)=>{
        throw error;
    });
};