import React, { useEffect } from 'react';
import { formatTime } from 'utils/formatters';

import './ProgressInputRange.scss';

export interface IProgressInputRangeProps {
    className?: string;
    progressInputRangeRef: React.RefObject<any>;
    progressTimeRef: React.RefObject<any>;
    audioRef: React.RefObject<any>;
    duration: number,
}

function ProgressInputRange(props: IProgressInputRangeProps) {
    const {
        className = '',
        progressInputRangeRef,
        progressTimeRef,
        audioRef,
        duration = 0,
    } = props;

    const handleProgressChange = () => {
        audioRef.current.currentTime = progressInputRangeRef.current.value;
    };

    useEffect(()=> {
        if (progressInputRangeRef && progressInputRangeRef.current) {
            progressInputRangeRef.current.max = duration;
        }
    }, [duration, progressInputRangeRef]);

    const classNames = `progress-Input-range ${className}`.trim();
    
    return (
        <div className={classNames}>
            <small 
                ref={progressTimeRef}
                className="progress-Input-range__time"
            />
            <input
                type="range"
                ref={progressInputRangeRef}
                defaultValue={0}
                onChange={handleProgressChange}
            />
            <small className="progress-Input-range__time-duration">
                {formatTime(duration)}
            </small>
        </div>
    );
}

export default ProgressInputRange;
