import React, {
    useEffect, 
    useRef 
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import SideBar from 'components/MusicPlayer/SideBar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IPlanObject } from 'interfaces';
import Transition from './Transition';

import './MusicDetailDialog.scss';

interface IMusicDetailDialogProps {
    className?: string;
    containerProps?: IPlanObject;
    currentTrack?: IPlanObject;
    open?: boolean;
    // eslint-disable-next-line no-unused-vars
    onClose?: (event: object, reason: string)=> any;
    fullScreen?: boolean;
    sidebar?: boolean;
    title?: string;
    transitionComponent?: React.ReactElement | any;
    children: React.ReactNode;
    [key: string]: any;
}

function MusicDetailDialog(props: IMusicDetailDialogProps) {
    const {
        className = '',
        containerProps = {},
        currentTrack = {},
        open = false,
        onClose = ()=> {},
        fullScreen = false,
        sidebar = true,
        children,
        title = '',
        transitionComponent = Transition,
        ...restProps
    } = props;

    const dialogRef: any = useRef();

    // scroll modal content to the top whenever track change
    useEffect(()=> {
        if (dialogRef && dialogRef.current) {
            const dialogContentRoot = dialogRef.current.querySelector('.MuiPaper-root');
            if (dialogContentRoot) {
                dialogContentRoot.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [currentTrack]);

    const classNames = `music-detail-dialog ${className}`.trim();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            className={classNames}
            ref={dialogRef}
            TransitionComponent={transitionComponent || undefined}
            scroll="paper"
            {...restProps}
        >
            <div 
                className="music-detail-dialog__wrap"
                {...containerProps}
            >
                <div className="music-detail-dialog__wrap-inner">
                    <DialogTitle className='music-detail-dialog__title'>
                        {title && (
                            <span >{title}</span>
                        )}
                        <IconButton 
                            className='music-detail-dialog__btn-close'
                            onClick={(e)=> { onClose(e, ''); }}
                        >
                            <KeyboardArrowDownIcon className='music-detail-dialog__icon-close'/>
                        </IconButton> 
                    </DialogTitle>
                    <DialogContent className='music-detail-dialog__content'>
                        {children}
                    </DialogContent>
                </div>
            </div>
            {sidebar && (
                <SideBar currentTrack={currentTrack} />
            )}
        </Dialog>
    );
}

export default MusicDetailDialog;
