import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import Footer from 'components/Footer';
import { drawerMenu } from 'constants/navigation';
import { SIGN_IN } from 'constants/routes';
import { IDrawerMenu } from 'interfaces';

import './DrawerMenu.scss';

interface IDrawerMenuProps {
    className?: string;
    // eslint-disable-next-line no-unused-vars
    toggleDrawer: (arg: boolean)=> any;
    isAuthenticated: boolean;
    signOutUser: ()=>any;
}

function DrawerMenu(props: IDrawerMenuProps) {
    const {
        className = '',
        toggleDrawer = ()=>{},
        isAuthenticated = false,
        signOutUser = () => {},
    } = props;


    const navigate = useNavigate();

    const handleClose = ()=>{
        toggleDrawer(false);
    };

    const handleClick = (url = '')=> {
        if (url) {
            navigate(url);
        }
    };

    const handleLogIn = () => {
        navigate(SIGN_IN);
    };

    const handleLogOff = async()=> {
        signOutUser();
    };

    const classNames = `drawer-menu ${className}`.trim();

    return (
        <Box 
            className={classNames}
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleClose}
        >
            <List className="drawer-menu__list">
                {drawerMenu.map((item = {} as IDrawerMenu) => (
                    <ListItem 
                        key={item.url}
                        disablePadding
                    >
                        <ListItemButton onClick={()=>{handleClick(item.url);}} >
                            <ListItemIcon>
                                <item.icon />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List className="drawer-menu__list">
                <ListItem disablePadding>
                    {!isAuthenticated && (
                        <ListItemButton onClick={handleLogIn}>
                            <ListItemIcon>
                                <LoginIcon />
                            </ListItemIcon>
                            <ListItemText primary="Log in" />
                        </ListItemButton>
                    )}
                    {isAuthenticated && (
                        <ListItemButton onClick={handleLogOff}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Log off" />
                        </ListItemButton>
                    )}
                </ListItem>
            </List>
           
            <Footer asColumn />
        </Box>
    );
}

export default DrawerMenu;
